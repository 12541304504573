import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { dispatch } from 'redux/actions/index';

const styles = {
  loadingPage: {
    height: '100%',
    width: '100%',
    backgroundColor: '#242424a8',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.7,
  },
  loaderContainer: {
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    overflow: 'visible',
    position: 'relative',
  },
  logoGContainer: {
    display: 'inline-block',
    position: 'relative',
    animation: '$float 6s ease-in-out infinite',
  },
  logoG: {
    width: '130px',
    height: '130px',
    position: 'relative',
    zIndex: 2,
    opacity: 0,
    transform: 'scale(0.9) rotate(5deg)',
    animation: '$revealG 0.6s cubic-bezier(0.65, 0, 0.35, 1) forwards 0.2s',
    shapeRendering: 'geometricPrecision',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      background: `repeating-linear-gradient(
        transparent,
        transparent 2px,
        rgba(0,0,0,0.1) 2px,
        rgba(0,0,0,0.1) 4px
      )`,
      opacity: 0.8,
      animation: '$scanlines 2.5s linear infinite',
      filter: 'grayscale(1)',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-150%',
      width: '100%',
      height: '100%',
      background: `linear-gradient(
        120deg,
        rgba(255,255,255,0.0) 0%,
        rgba(255,255,255,0.4) 50%,
        rgba(255,255,255,0.0) 100%
      )`,
      transform: 'skewX(-20deg)',
      opacity: 0.4,
      pointerEvents: 'none',
      animation: '$reflectionSweep 2s ease-in-out infinite',
    },
  },
  vortexArc: {
    position: 'absolute',
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    border: '2px solid transparent',
    borderTop: '2px solid rgba(200, 200, 200, 0.8)',
    borderBottom: '2px solid rgba(100, 100, 100, 0.5)',
    transformStyle: 'preserve-3d',
    animation: '$vortexSpin 4s ease-in-out infinite',
    zIndex: 1,
    opacity: 0,
  },
  arc1: { animationDelay: '0s' },
  arc2: { animationDelay: '0.15s', transform: 'rotateX(30deg)' },
  arc3: { animationDelay: '0.3s', transform: 'rotateY(45deg)' },
  arc4: { animationDelay: '0.45s', transform: 'rotateX(-30deg)' },
  warpBg: {
    position: 'absolute',
    width: 0,
    height: 0,
    background: 'radial-gradient(circle, rgba(150, 150, 150, 0.3), transparent)',
    borderRadius: '50%',
    animation: '$warpExpand 3.5s ease-out forwards',
    zIndex: 0,
  },

  '@keyframes revealG': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.9) rotate(5deg)',
      filter: 'blur(0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1) rotate(0deg)',
      filter: 'blur(0)',
    },
  },
  '@keyframes float': {
    '0%': { transform: 'translate(0, 0) rotate(0deg)' },
    '25%': { transform: 'translate(1px, -1px) rotate(0.5deg)' },
    '50%': { transform: 'translate(0, 1px) rotate(0deg)' },
    '75%': { transform: 'translate(-1px, 0) rotate(-0.5deg)' },
    '100%': { transform: 'translate(0, 0) rotate(0deg)' },
  },
  '@keyframes scanlines': {
    '0%': { backgroundPosition: '0 0' },
    '50%': { backgroundPosition: '0 4px' },
    '100%': { backgroundPosition: '0 0' },
  },
  '@keyframes reflectionSweep': {
    '0%': { left: '-150%' },
    '50%': { left: '100%' },
    '100%': { left: '150%' },
  },
  '@keyframes vortexSpin': {
    '0%': {
      opacity: 0,
      transform: 'rotate(0deg) translateZ(-80px) scale(0.5)',
    },
    '20%': {
      opacity: 1,
      transform: 'rotate(180deg) translateZ(40px) scale(1.1)',
    },
    '50%': {
      opacity: 0.9,
      transform: 'rotate(360deg) translateZ(60px) scale(1.3)',
    },
    '80%': {
      opacity: 0.6,
      transform: 'rotate(540deg) translateZ(20px) scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'rotate(720deg) translateZ(-80px) scale(0.5)',
    },
  },
  '@keyframes warpExpand': {
    '0%': { width: 0, height: 0, opacity: 0.5 },
    '50%': { width: '180px', height: '180px', opacity: 0.2 },
    '100%': { width: '250px', height: '250px', opacity: 0 },
  },
};

class Loading extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loadingPage}>
        <div className={classes.loaderContainer}>
          <div className={classes.logoGContainer}>
            <svg
              className={classes.logoG}
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              preserveAspectRatio="xMidYMid meet"
            >
              <defs>
                {/* A single diagonal metallic gradient that rotates around the center */}
                <linearGradient
                  id="diamondGradient"
                  gradientUnits="userSpaceOnUse"
                  x1="0"
                  y1="0"
                  x2="256"
                  y2="256"
                >
                  {/* This SMIL animation rotates the gradient around the center (128,128) */}
                  <animateTransform
                    attributeName="gradientTransform"
                    type="rotate"
                    from="0 128 128"
                    to="360 128 128"
                    dur="6s"
                    repeatCount="indefinite"
                  />
                  <stop offset="0" stopColor="#ffffff" />
                  <stop offset="0.2" stopColor="#e0e0e0" />
                  <stop offset="0.4" stopColor="#808080" />
                  <stop offset="0.5" stopColor="#6e6e6e" />
                  <stop offset="0.8" stopColor="#d0d0d0" />
                  <stop offset="1" stopColor="#ffffff" />
                </linearGradient>
              </defs>

              <g id="XMLID_1_">
                <g id="XMLID_27_">
                  {/* Each polygon now uses our single rotating gradient */}
                  <polygon
                    fill="url(#diamondGradient)"
                    points="55.9,128.5 40.4,144.3 40.4,112.7 43.6,115.9"
                  />
                  <polygon
                    fill="url(#diamondGradient)"
                    points="215.6,112.7 215.6,134.7 193.9,134.7"
                  />
                  <polygon
                    fill="url(#diamondGradient)"
                    points="215.6,138.3 215.6,144.3 128,233.5 43.5,147.5 43.6,147.5 45.6,145.5 59.1,131.7 128,201.9 190.4,138.3"
                  />
                </g>

                {/* Smaller facets with partial opacity */}
                <g opacity="0.6">
                  <g>
                    <polygon
                      fill="url(#diamondGradient)"
                      opacity="0.51"
                      points="215.6,120.2 215.6,134.7 193.9,134.7 209,119.6"
                    />
                  </g>
                </g>

                <g opacity="0.6">
                  <polygon
                    fill="url(#diamondGradient)"
                    points="56,128.5 55.9,128.5 40.4,144.3 41.1,118.4 43.6,115.9"
                  />
                </g>

                {/* Main top diamond shape */}
                <g>
                  <polygon
                    fill="url(#diamondGradient)"
                    points="215.6,112.7 184.5,112.7 128,55.1 56,128.5 55.9,128.5 40.4,144.3 40.4,112.7 128,23.5"
                  />
                </g>

                {/* Bottom bar */}
                <g>
                  <g>
                    <polygon
                      fill="url(#diamondGradient)"
                      points="215.6,112.7 215.6,134.7 128,134.7 141.2,112.7"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className={`${classes.vortexArc} ${classes.arc1}`} />
          <div className={`${classes.vortexArc} ${classes.arc2}`} />
          <div className={`${classes.vortexArc} ${classes.arc3}`} />
          <div className={`${classes.vortexArc} ${classes.arc4}`} />

          <div className={classes.warpBg} />
        </div>
      </div>
    );
  }
}

export default connect(() => ({}), { dispatch })(withStyles(styles)(Loading));
